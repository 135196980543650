//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
export default {
    props: ['invite'],
    data() {
      return {
        parsedInviteDate: moment(this.invite.invite_date).format('LL'),
        parsedInviteTime: moment(this.invite.invite_date).format('LT'),
        isAccepting: false,
        isDeclining: false
      }
    },
    methods: {
      accept() {
        this.isAccepting = true;
        this.$http({
          url: 'entities/' + this.$route.params.id + '/accept',
          method: 'POST'
        }).then((response) => {
          this.isAccepting = false;
          if (response.data['status'] == 'invite_accepted') {
            window.location.reload();
          } else if (response.data['status'] == 'no_invites') {
            this.$router.push({name: 'Profile'});
            this.$q.notify({
              message: 'The invite does not exist or is no longer available.',
              color: 'primary',
              icon: 'error'
            });
          } else if (response.data['status'] == 'already_member') {
            window.location.reload();
          } else {
            this.$q.notify({
              message: 'An error has occurred while trying to accept. Please try again later.',
              color: 'negative',
              icon: 'error'
            });
          }
        })
      },
      decline() {
        this.isDeclining = true;
        this.$http({
          url: 'entities/' + this.$route.params.id + '/decline',
          method: 'POST'
        }).then((response) => {
          this.isDeclining = false;
          if (response.data['status'] == 'invite_declined') {
            this.$router.push({name: 'Profile', params: {id: this.$store.state.user.id}});
            this.$q.notify({
              message: 'You have declined the invite.',
              color: 'primary'
            });
          } else {
            this.$q.notify({
              message: 'An error has occurred while trying to accept. Please try again later.',
              color: 'negative',
              icon: 'error'
            });
          }
        });        
      }
    }
}
